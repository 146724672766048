// credits: https://github.com/SteveRidout/mongo-object-time

const minDate = new Date(0);
const maxDate = new Date(parseInt("ffffffff", 16) * 1000);

export const objectIdFromDate = function (date) {
    if (date < minDate || date > maxDate) 
        throw new Error("date must be between " + minDate.getFullYear() +
            " and " + maxDate.getFullYear());

        var pad = "00000000";
        var hexSeconds = Math.floor(date.getTime() / 1000).toString(16)
        return pad.substring(0, pad.length - hexSeconds.length) + hexSeconds + "0000000000000000";
}

export const dateFromObjectId = function (objectId) {
    return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
};


import http from "../http-common";

class Service {
    getPrices(){
        return http.get('/payment/prices');
    }
    createCheckoutSession(priceId){
        return http.post(`/payment/create-checkout-session/${priceId}`);
    }
    getCustomerPortal(customerId){
        return http.get(`/payment/customer-portal/${customerId}`);
    }
}

export const PaymentService = new Service();
 

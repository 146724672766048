<!-- just for testing -->
<template>
    <div ref="license" class="modal fade" data-bs-backdrop="static" tabindex="-1">
        <div class="modal-dialog modal-m">
            <div class="modal-content modal-padded modal-united" v-if="management">
                <div class="modal-header">
                    <h4 class="mt-3 mb-3">{{$t('license.license')}}</h4>
                </div>
                <div class="modal-body">
                    <p>{{licenseText()}}</p>
                    <p>{{$t('license.disclaimer')}}</p>
                </div>
                <div class="modal-footer justify-content-around">
                    <button type="button" class="btn btn-primary" @click="close">{{$t('navigation.ok')}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Modal} from "bootstrap";

export default {
    props: ['management'],
    data() {
        return {
            testHours: import.meta.env.VITE_TEST_HOURS
        }
    },
    methods: {
        async show(){
            Modal.getOrCreateInstance(this.$refs.license).show();
        },
        close(){
            Modal.getOrCreateInstance(this.$refs.license).hide();
        },
        licenseText(){
            if (this.management.effectiveTherapy && this.management.effectiveTherapy.trial){
                if (this.management.course){
                    if (this.management.courseInFuture)
                        return this.$t('license.upcomingLicenseCourseStartsAt<date>', {
                            date: new Date(this.management.course.start).toLocaleDateString()
                        });
                    else if (this.management.active)
                        return this.$tc('license.licenseExpired<k>Months<count>Hours<l>MinutesLeft', this.testHours, {k: this.management.licenseMonths, l: Math.floor(this.testHours * 60 - this.management.activeMinutes)});
                    else
                        return this.$tc('license.licenseExpiredInactive<k>MonthsOver<count>Hours', this.testHours, {k: this.management.licenseMonths});
                } else {
                    if (this.management.active)
                        return this.$tc('license.guestLicense<k>MinutesOf<count>HoursLeft', this.testHours, {k: Math.floor(this.testHours * 60 - this.management.activeMinutes)});
                    else 
                        return this.$tc('license.guestLicenseInactiveTrainedOver<count>Hours', this.testHours);
                }
            } else 
                return this.$t('license.license<k>MonthsExpiresAt<date>', {k: this.management.licenseMonths, date: new Date(this.management.licenseEndFormatted).toLocaleDateString()});
        }
    }
}

</script>


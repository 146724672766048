import {createI18n} from 'vue-i18n';

// TODO: when we have many languages, we should load lazily, see e.g. https://kazupon.github.io/vue-i18n/guide/lazy-loading.html
import de from './locales/de.json';
import de_DE from './locales/de-DE.json';
import en from './locales/en.json';
import en_GB from './locales/en-GB.json';

const messages = {de, en, 'de-DE': de_DE, 'en-GB': en_GB};

function checkDefaultLanguage() {
    let matched = null;
    let languages = Object.getOwnPropertyNames(messages);
    languages.forEach(lang => {
        if (lang === navigator.language) {
            matched = lang;
        }
    });
    if (!matched) {
        languages.forEach(lang => {
            let languagePartials = navigator.language.split('-')[0];
            if (lang === languagePartials) {
                matched = lang;
            }
        });
    }
    return matched;
}

export default createI18n({
    locale: checkDefaultLanguage(),
    fallbackLocale: import.meta.env.VITE_I18N_FALLBACK_LOCALE,
    messages
});


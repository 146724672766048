// for errors outside vue components
// hm, sometimes, this leads to problems during console debugging
/*window.onerror = function(message, source, lineno, colno, error) {
    debugger;
    alert("Unexpected error - please contact support");
    throw error;
};*/

import App from './app.vue'

import {createApp, h
    //, configureCompat
} from 'vue';
import router from './router';
import {createPinia} from 'pinia';
import filters from './filters';
import Vue3Lottie from "vue3-lottie";
import 'vue3-lottie/dist/style.css';
//import Vue3linkify from "vue-3-linkify";
import {SettingsService} from "./services/settings-service";
import {reportRequestError} from './utils/messaging.js';

import "bootstrap";

//import i18n from './i18nX';
import i18n from './i18n';

// no, this includes an own, outdated version of keycloak-js: import VueKeyCloak from '@dsb-norge/vue-keycloak-js';

import keycloak from './plugins/keycloak';

/*
import {createI18n} from 'vue-i18n'
import de from './locales/de.json';
import en from './locales/en.json';
const i18n = createI18n({
    locale: 'de',
    messages: {de, en}
});
*/

//import draggable from 'vuedraggable';
//import VueCompositionApi from '@vue/composition-api';

import http from "./http-common";

import VueCookieComply from 'vue-cookie-comply'
import 'vue-cookie-comply/dist/style.css'

/* hm, VeeValidate together with i18n generated strange error messages like 
   'must be at least 1: characters'
   -> resort to using browser-validation mainly and manual for the rest, like here: https://vuejs.org/v2/cookbook/form-validation.html
Vue.use(VeeValidate, 
    {
    i18n,
    dictionary: {
        en: {
            messages: enValidation.messages,
            attributes: {mail: "e-mail"}
        },
        de: {
            messages: deValidation.messages,
            attributes: {mail: "mehail"}
        }
    }
 }   
);
 */


//Vue.use(VueCompositionApi);


//import Editor from "vue-editor-js/src";
//Vue.use(Editor);

/*configureCompat({
    RENDER_FUNCTION: false,
    ATTR_FALSE_VALUE: false
});*/

const app = createApp({
    render: () => h(App),
    // FIXME: probably handle with pinia
    onUpdateNumberOfNotifications: () => {
        this.loadNumberOfNotifications();
    }
});

export {app};

app.config.globalProperties.$filters = filters;

// for errors inside vue components that 
app.config.errorHandler = (err, vm, info) => {
    alert("Unexpected error - please contact support");
    // TODO: probably display something
    throw err;
};

app.use(createPinia());
app.use(i18n);
//app.use(draggable);

app.use(VueCookieComply);

app.use(keycloak);

app.use(Vue3Lottie);

// no, garbles contents in v-for loop
//app.use(Vue3linkify);

// now set up keycloak
const keycloakInitOptions = {
    //onLoad: 'login-required'
    onLoad: 'check-sso',
    checkLoginIframe: false
};

/* add an interceptor for each http request:
  - set keycloak access token
  - make sure token is still valid
  */

// https://github.com/dsb-norge/vue-keycloak-js
function tokenInterceptor () {
    http.interceptors.request.use(async config => {
        if (app.config.globalProperties.$keycloak.authenticated) {

            const minValidSeconds = 10;
            try {
                await app.config.globalProperties.$keycloak.updateToken(minValidSeconds);
            } catch (err){
                console.log("updateToken failed - probably our session is expired");
                debugger;
                // do nothing. request will fail and we will be redirected to login
            }

            config.headers.Authorization = `Bearer ${app.config.globalProperties.$keycloak.token}`;
        }
        return config;
    }, error => {
        return Promise.reject(error);
    });
}

console.log("initializing keycloak...");
app.config.globalProperties.$keycloak.init(keycloakInitOptions)
    .then(async (isAuthenticated) => {
        console.log("initializing done, isAuthenticated = " + isAuthenticated);

        if (isAuthenticated){
            // this is the locale used for translating (operation language).
            var locale = app.config.globalProperties.$keycloak.idTokenParsed.locale || '';
            if (!locale)
                locale = import.meta.env.VITE_I18N_FALLBACK_LOCALE;

            i18n.global.locale = locale;


        } else {
            // take from lang parameter (our custom keycloak theme should equip links with that)
            if (location.search.includes("lang=")){
                const lang = location.search.replace(/.*lang=/, '').replace(/&.*/, '');
                i18n.global.locale = lang;
            } else
                console.warn("no language information!");
        }

        tokenInterceptor();

        if (app.config.globalProperties.$keycloak.idTokenParsed && 
            sessionStorage.sessionId != app.config.globalProperties.$keycloak.idTokenParsed.sid){
            sessionStorage.sessionId = app.config.globalProperties.$keycloak.idTokenParsed.sid;
            console.log("fresh login");

            // do tasks required after new login
            try {
                const userId = app.config.globalProperties.$keycloak.subject;
                await SettingsService.setAutoValues(userId);
            } catch (err){
                reportRequestError(err);
            }
        }

        app.use(router);
        app.mount("#app");
    })
    .catch((err) => {
        console.error("error initializing keycloak:", err);
        debugger;
        if (navigator.onLine)
            alert("Could not connect to authentication service. Now trying again. If this persists please contact support.");
        else
            alert("Could not connect to authentication service because you are offline. Please connect and try again");
        location.reload();
    });


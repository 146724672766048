import http from "../http-common";

class Service {
    get(tags){
        return http.get('/info');
    }
}

export const InfoService = new Service();


import {useMessageStore} from '../stores/message-store';

import router from '../router';
import {useRouter} from 'vue-router';

import i18n from "../i18n";
const {t} = i18n.global;

import {app} from '../main';

// if there are server errors, returns a dictionary of them, otherwise undefined
export const reportRequestError = (error) => {

    // Note: we cannot initialize messageStore outside because pinia won't be initialized yet, https://pinia.vuejs.org/core-concepts/outside-component-usage.html#single-page-applications
    const messageStore = useMessageStore();

    const keycloak = app.config.globalProperties.$keycloak;

    if (error.message == "canceled"){
        console.log("request was cancelled - assuming that this was intentional");
        return;
    }

    if (error.toString() == 'Error: Network Error'){

        if (navigator.onLine)
            messageStore.warn(t('errors.serverUnreachable'));
        else 
            messageStore.warn(t('errors.offline'));
    }
    // a special message meant for displaying directly to the user?
    else if (error.response && error.response.data && error.response.data.userMessage) {
        messageStore.warn(t(error.response.data.userMessage, error.response.data));
    }
    // an error relating to a certain field?
    else if (error.response && error.response.data && error.response.data.formErrors)
        return error.response.data.formErrors;
    else if (error.response && error.response.status == 401) {
        // OUTDATED - this was when using our own authentication
        // we expected to be authenticated, but we are not -> token must be wrong
        // usually just expired

        messageStore.warn(t('authentication.invalidToken'));

        /*
        const redirectPath = sessionStorage.getItem('redirectPath');
        if (redirectPath)
            // can happen e.g. if two components try to load user data, e.g. app.vue and dashboard.vue
            console.log(`already planning to redirect to '${redirectPath}' -> ignoring current vue.$route.fullPath='${vue.$route.fullPath}'`);


        else
            //sessionStorage.setItem('redirectPath', vue.$route.fullPath);
            // trying to avoid the need to have vue
            sessionStorage.setItem('redirectPath', window.location.href);

*/
        const redirectUri = window.location.href;

        debugger;
        keycloak.logout({redirectUri});
    }
    else if (error.response && error.response.status == 403) {

        if (!keycloak.authenticated ||
            keycloak.isTokenExpired()){
            // prune away keycloak postfix
            const redirectUri = window.location.href.replace(/#error.*/, "");

            /* should not be necessary anymore as we are now blocking all beforeunload listeners below
            if (uploadStore.uploads > 0){
                // give up uploads, otherwise user will get confirmation dialog if closing is ok, which would be confusing since user probably did not intend to close
                console.warn(`silently giving up ${uploadStore.uploads} uploads...`);
                uploadStore.uploads = 0;
            }*/

            // stop any beforeunload listeners from firing, otherwise user will get confirmation dialog if closing is ok, which would be confusing since user probably did not intend to close
            // block listeners by using 'capture', https://stackoverflow.com/a/46986927
            window.addEventListener('beforeunload', function(event) {
                event.stopImmediatePropagation();
                return null;
            }, {capture: true});

            debugger;

            keycloak.login({redirectUri});
        } else {
            // insufficient permissions
            messageStore.warn(t('authentication.forbidden'));
            router.push('/');
        }
    }
    /* hmm, no. map this to unknown error.
    else if (error.response && error.response.status == 404) {
        messageStore.warn(t('authentication.notFound'));
        router.push('/');
    }*/
    else if (error.response && error.response.status == 412) {
        messageStore.warn(t('errors.preconditionFailed'));
    } else if (error.response && error.response.status == 500) {
        messageStore.warn(t('errors.internalServerError'));
    } else {
        console.log("unclassified error:", error);
        messageStore.warn(t('errors.unknownError'));
        debugger;
    }
};


import http from "../http-common";

class Service {
    getForMeGuestTherapySafe(){
        return http.get('/management/me');
    }
    get(userId){
        return http.get(`/management/users/${userId}`);
    }
    getUsingTherapyOne(therapyId){
        return http.get(`/management/usingTherapyOne/${therapyId}`);
    }
    getTherapists(userId){
        return http.get(`/management/therapists/${userId}`);
    }
    set(management){
        return http.put('/management/users',
            {management});
    }
    setTherapy(therapyId){
        return http.put('/management/therapy', {therapyId});
    }
    optOutOfCourseChat(){
        return http.put('/management/optOutOfCourseChat');
    }
}

export const ManagementService = new Service();
 

import axios from "axios";
import Cookies from 'js-cookie';
import {app} from './main';

console.log("DEV=" + import.meta.env.DEV);

const customAxios = axios.create({
    baseURL: (import.meta.env.DEV ? 
        'http://localhost:8100/api' :
        '/api'),
    headers: {
        "Content-type": "application/json"
    },
    withCredentials: true
});

// A CORS error is caused when keycloak-connect at the backend tries to 
// redirect to login.
// Can we fix this by checking here whether we are authenticated?
// hmm, well, we first would have to know if the request requires authentication.
// also, we seem to have found a solution to the CORS error by switching off
// the redirect at the backend (see backend/app/middlewares/keycloak.js)
//customAxios.interceptors.request.use(async (request) => {
//    const authenticated = app.config.globalProperties.$keycloak.authenticated;
//    if (!authenticated){
//        const basePath = window.location.origin.toString();
//        const redirectUri = basePath +
//            // prune away keycloak postfix
//            to.fullPath.replace(/#error.*/, "");
//        console.log("request interceptor: login...");
//        app.config.globalProperties.$keycloak.login({redirectUri});
//        // FIXME: request is lost
//    }
//    return request;
//});

// https://stackoverflow.com/a/62178913
/*
customAxios.interceptors.request.use(async (request) => {
    try {
        console.log("cookies: ", Cookies.get());
        //const csrf = Cookies.get('XSRF-TOKEN');
        const csrf = Cookies.get('_csrf');
        request.withCredentials = true;

        if (csrf) {
            request.headers.common['XSRF-TOKEN'] = csrf;
        }
        debugger;

        return request;
    } catch (err) {
        throw new Error(`axios# Problem with request during pre-flight phase: ${err}.`);
    }
});
*/


export default customAxios;

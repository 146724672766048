import http from '../http-common';

class Service{
    get(userId){
        return http.get(`/settings/users/${userId}`);
    }

    set(settings){
        const userId = settings.user;
        return http.post(`/settings/users/${userId}`, {settings});
    }

    setAutoValues(userId){
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const localeForTime = navigator.language;

        return http.put(`/settings/users/auto/${userId}`, {timezone, localeForTime});
    }
}

export const SettingsService = new Service();


import Keycloak from 'keycloak-js';

const keycloakConfig = {
    url: import.meta.env.VITE_KEYCLOAK_URL,
    realm: import.meta.env.VITE_KEYCLOAK_REALM,
    clientId: import.meta.env.VITE_KEYCLOAK_CLIENT,
    sslRequired: "external",
    resource: "testclient",
    publicClient: true,
    confidentialPort: 0
};

const keycloak = new Keycloak(keycloakConfig);

keycloak.onTokenExpired = async () => {
    console.log("token expired");

    /* hm, since we now do updateToken before each request (see tokenInterceptor in ../main.js), we don't need to take action here.
       also, updatingToken here had an edge case
       (when sending try and updateToken here coincided, the user was redirected to dashboard, with a confusing error message about not being authorized. */
    const doRefresh = false;

    if (!doRefresh)
        return;
    else 
        // TODO: block beforeunload listeners below
        throw new Error("implement me");

    try {
        await keycloak.updateToken();
        console.log("succeeded refreshing token");
    } catch (err){
        console.log("error refreshing token:", err);
        // TODO: block beforeunload listeners here, see ../utils/messaging.js
        location.reload();
    };
};

keycloak.getRealmRoles = () => {
    return keycloak.realmAccess.roles;
}

keycloak.checkTokenOrLogin = async () => {
    const minValidSeconds = 10;
    try {
        await keycloak.updateToken(minValidSeconds);
    } catch (err){
        // remove error string 
        const redirectUri = window.location.href.replace(/[#&]error.*/, "");

        // stop any beforeunload listeners from firing, otherwise user will get confirmation dialog if closing is ok, which would be confusing since user probably did not intend to close
        // block listeners by using 'capture', https://stackoverflow.com/a/46986927
        window.addEventListener('beforeunload', function(event) {
            event.stopImmediatePropagation();
            return null;
        }, {capture: true});

        debugger;

        keycloak.login({redirectUri});
    }
};

export default {
    install: (app, options) => {
        app.config.globalProperties.$keycloak = keycloak;
    }
};







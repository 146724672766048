import http from "../http-common";

class Service {
    getLatest(userId){
        return http.get(`/goals/${userId}/latest`);
    }
    add(userId, goal, saveEvenUnchanged){
        return http.post(`/goals/${userId}`,
            {goal, saveEvenUnchanged});
    }
}

export const GoalService = new Service();
 

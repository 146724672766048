import {dateFromObjectId} from "./utils/db";

function truncate(text, toLength=15, ellipsisString="\u2026"){
    if (!text)
        return text;

    if (text.length > toLength) {
        if (ellipsisString.length >= toLength)
            throw new Error("ellipsisString too long: ", ellipsisString);
        return text.substring(0, toLength - ellipsisString.length) + ellipsisString;
    } else {
        return text;
    }
}

function ignoreEMPTY(text){
    return text.replace(/EMPTY/g, '');
}

export default {
    truncate,
    ignoreEMPTY,
    exerciseSummary(exercise){
        /*if (exercise.type == "speaking")
            return truncate("speak: " + exercise.text, 20);
        else {
            return truncate("theory: " + 
                ((exercise.editorjsData && exercise.editorjsData.blocks && exercise.editorjsData.blocks.length && exercise.editorjsData.blocks[0].data && exercise.editorjsData.blocks[0].data.text) || "..."),
            20);
        }*/
        return `${exercise.name} (${exercise.descriptiveId})`;
    },
    dateFromObjectId(text){
        return (dateFromObjectId(text)).toLocaleString();
    },
    timeFromObjectId(text){
        return (dateFromObjectId(text)).toLocaleTimeString();
    },
    round(text, places=0){
        return parseFloat(text).toFixed(places);
    }
};

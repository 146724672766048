import http from "../http-common";

class NotificationService {
    subscribe(subscription, data, vue){
        return http.post('/notifications/subscribe', 
            subscription);
    }

}

export default new NotificationService();


import http from "../http-common";

class ActivityService {
    getTodayPending(userId){
        return http.get(`/activity/todayPending/${userId}`);
    }
    refresh(userId){
        return http.post(`/activity/refresh/${userId}`);
    }
}
export default new ActivityService();

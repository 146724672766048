import {defineStore} from 'pinia';

const initialState = {
    uploads: 0
};

export const useUploadStore = defineStore('upload', {
    state: () => {
        return initialState;
    }
});



import {app} from '../main';

function haveMappedRealmRole(fauchiRole){
    return containsMappedRole(
        app.config.globalProperties.$keycloak.getRealmRoles(),
        fauchiRole);
}

const realmRoleMappingsJSON = import.meta.env.VITE_REALM_ROLE_MAPPINGS;
const realmRoleMappings = realmRoleMappingsJSON ? JSON.parse(import.meta.env.VITE_REALM_ROLE_MAPPINGS) : null;

function containsMappedRole(keycloakRoles, fauchiRole){
    var requiredRoles;
    if (realmRoleMappings)
        requiredRoles = realmRoleMappings[fauchiRole];
    else
        requiredRoles = [fauchiRole];
    if (!requiredRoles)
        throw new Error("could not find mapping for fauchiRole=" + fauchiRole);

    const keycloak = app.config.globalProperties.$keycloak;

    const doesContain = requiredRoles.every(
        r => keycloakRoles.includes(r));
    //console.log(`fauchiRole ${fauchiRole} in ${keycloakRoles} ? ${doesContain}`);
    return doesContain;
};

export {haveMappedRealmRole, containsMappedRole};

import {defineStore} from 'pinia';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
    warnings: {},
    successes: {},
    informations: {}
};

const waitSeconds1 = 5;
const waitSeconds2 = 0.3;

export const useMessageStore = defineStore('message', {
    state: () => {
        return initialState;
    },
    actions: {
        inform(text, faqSection = null, linkTarget=null, linkText=null){
            const id = uuidv4();
            this.informations[id] = {text, faqSection, linkTarget, linkText};
            setTimeout(() => {
                this.arrangeMessages();
            });
            setTimeout(() => {
                if (!this.informations[id])
                    return;
                this.informations[id].decaying = true;
                setTimeout(() => { 
                    this.clearInformation(id);
                }, waitSeconds2 * 1000);
            }, waitSeconds1 * 1000);
        },
        praise(text, faqSection = null){
            const id = uuidv4();
            this.successes[id] = {text, faqSection};
            setTimeout(() => {
                this.arrangeMessages();
            });
            setTimeout(() => {
                if (!this.successes[id])
                    return;
                this.successes[id].decaying = true;
                setTimeout(() => { 
                    this.clearSuccess(id);
                }, waitSeconds2 * 1000);
            }, waitSeconds1 * 1000);
        },
        warn(text, faqSection = null){
            const id = uuidv4();
            this.warnings[id] = {text, faqSection};
            setTimeout(() => {
                this.arrangeMessages();
            });
            setTimeout(() => {
                if (!this.warnings[id])
                    return;
                this.warnings[id].decaying = true;
                setTimeout(() => {
                    this.clearWarning(id);
                }, waitSeconds2 * 1000);
            }, waitSeconds1 * 1000);
        },
        clearInformation(id){
            if (this.informations[id])
                delete(this.informations[id]);
            else
                console.log("not cleaning information, already gone: " + id);
            setTimeout(() => {
                this.arrangeMessages();
            });
        },
        clearSuccess(id){
            if (this.successes[id])
                delete(this.successes[id]);
            else
                console.log("not cleaning information, already gone: " + id);
            setTimeout(() => {
                this.arrangeMessages();
            });
        },
        clearWarning(id){
            if (this.warnings[id])
                delete(this.warnings[id]);
            else
                console.log("not cleaning warning, already gone: " + id);
            setTimeout(() => {
                this.arrangeMessages();
            });
        },
        arrangeMessages(){
            // see prototype: toggle_alert in js/custon.js

            var h = 0, d = 0;
            //var el = document.getElementById(a);
            //el.style.display = (el.style.display == 'block') ? 'none' : 'block';

            var els = document.getElementsByClassName('kst-alert');

            for(var i=0; i < els.length; i++) {
                els[i].style.top = 30 + d + 'px';
                els[i].style.display = 'flex';

                h = els[i].offsetHeight;
                if(h > 0) {
                    d += h + 10;
                }
            }
        }
    }
});
           
